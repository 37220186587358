import CrypPay from "./CrypPay";

class Company {
    checkToken(){
        CrypPay.interceptors.response.use(
            response => response,
            error => {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem("auth");
                    window.location.href = "/login";
                }
                return Promise.reject(error);
            }
        );
    }

    
    getList = async ({name, email, id, currentPage, offset}) => {
        this.checkToken()
        const response = await CrypPay.get("/Company", {
            params: {
                name: name,
                id: id,
                email: email,
                currentPage: currentPage,
                offset: offset
            }
        });

        return response.data.response;
    }

    getListPagination = async ({name, email, id}) => {
        this.checkToken()
        const response = await CrypPay.get("/Company/Pagination", {
            params: {
                name: name,
                id: id,
                email: email,
                splitBy: 10
            }
        });

        return response.data.response;
    }

    getCompany = async (id) => {
        this.checkToken()
        const response = await CrypPay.get("/Company", {
            params: {
                id: id
            }
        });
        
        return response.data.response[0];
    }

    updateCompanyStatus = async (id, companyStatus) => {
        this.checkToken()
        const response = await CrypPay.post("/Company/UpdateCompanyStatus", {
            id: id,
            companyStatus: companyStatus
        });

        return response.data.response;
    }

    addCommentary = async (id, commentary) => {
        this.checkToken()
        const response = await CrypPay.put(`/Company/${id}/AddCommentary`, JSON.stringify(commentary), {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return response.data.response;
    }

    restore = async (id) => {
        this.checkToken()
        const response = await CrypPay.put(`/Company/${id}/Restore`);
        return response.data.response;
    }

    suspend = async (id) => {
        this.checkToken()
        const response = await CrypPay.put(`/Company/${id}/Suspend`);
        return response.data.response;
    }

    delete = async (id) => {
        this.checkToken()
        const response = await CrypPay.delete("/Company", {
            params: {
                id: id
            }
        });

        return response.data.response;
    }
}

export default new Company();