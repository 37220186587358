import AuthProvider from "./providers/authProvider";
import Router from "./routes/Router";

function App() {
    return (
        <AuthProvider>
            <Router/>
        </AuthProvider>
    );
}

export default App;