import CrypPay from "./CrypPay";

class Recommendations {
    checkToken(){
        CrypPay.interceptors.response.use(
            response => response,
            error => {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem("auth");
                    window.location.href = "/login";
                }
                return Promise.reject(error);
            }
        );
    }

    getRecommendations = async (params) => {
        this.checkToken()
        const response = await CrypPay.get("/CustomerBusinessRecommendation", {
            params: params,
        });

        return response.data.response;
    }

    getCoincidences = async (params) => {
        this.checkToken()
        const response = await CrypPay.get("/CustomerBusinessRecommendation/GetCoincidences", {
            params: params,
        });

        return response.data.response;
    }

    getListPagination = async (params) => {
        this.checkToken()
        const response = await CrypPay.get("/CustomerBusinessRecommendation/Pagination", {
            params: params
        });
    
        return response.data.response;
    }
    

}

export default new Recommendations();
