import {useEffect, useState} from "react";
import M from "materialize-css";

import Modal from "../../components/Modal/Modal";

import Company from "../../api/Company";

function CompanyModal({companyId, displayed, handleClose}) {
    const [company, setCompany] = useState({});

    const [showPictureModal, setShowPictureModal] = useState(false);
    const [image, setImage] = useState("");

    const [showApproveCompanyModal, setShowApproveCompanyModal] = useState(false);
    const [showRejectCompanyModal, setShowRejectCompanyModal] = useState(false);
    const [commentary, setCommentary] = useState("");

    const [showActivateCompanyModal, setShowActivateCompanyModal] = useState(false);
    const [showSuspendCompanyModal, setShowSuspendCompanyModal] = useState(false);

    const [showDeleteCompanyModal, setShowDeleteCompanyModal] = useState(false);

    // Load company data
    useEffect(() => {
        if (displayed) {
            getCompany();
        }
    }, [displayed]);

    // Request company data
    const getCompany = async () => {
        const response = await Company.getCompany(companyId);
        setCompany(response);
        console.log(response)
    }

    const handleCompanyStatus = async (status) => {
        try {
            if (status === 3) {
                await Company.addCommentary(company.id, commentary);
            }

            await Company.updateCompanyStatus(company.id, status);
            M.toast({
                html: status === 2 ? 'La compañia ha sido aprobada' : 'La compañia ha sido rechazada',
                classes: 'green'
            });
        } catch (e) {
            console.error(e);
            M.toast({
                html: 'No se pudieron aplicar los cambios',
                classes: 'red'
            });
        }

        // Close all modals
        setShowApproveCompanyModal(false);
        setShowRejectCompanyModal(false);

        // Refresh company data
        await getCompany();
    }

    const handleActivateSuspendCompany = async (status) => {
        try {
            switch (status) {
                case 1: // Activate
                    await Company.restore(company.id);
                    M.toast({
                        html: 'La compañia ha sido activada',
                        classes: 'green'
                    });
                    break;

                case 2: // Suspend
                    await Company.suspend(company.id);
                    M.toast({
                        html: 'La compañia ha sido suspendida',
                        classes: 'green'
                    });
                    break;
            }
        } catch (e) {
            console.error(e);
            M.toast({
                html: 'No se pudieron aplicar los cambios',
                classes: 'red'
            });
        }

        // Close all modals
        setShowActivateCompanyModal(false);
        setShowSuspendCompanyModal(false);

        // Refresh company data
        await getCompany();
    }

    const handleDeleteCompany = async () => {
        try {
            await Company.delete(company.id);
            M.toast({
                html: 'La compañia ha sido eliminada',
                classes: 'green'
            });

            // Close Company Modal
            handleClose(false);
        } catch (e) {
            console.error(e);
            M.toast({
                html: 'No se pudieron aplicar los cambios',
                classes: 'red'
            });
        } finally {
            // Close all modals
            setShowDeleteCompanyModal(false);
        }
    }

    const renderedPictures = () => {
        if (company.officialDoccumentation) {
            return (
                <>
                    {
                        company.officialDoccumentation.officialIdMedia.url &&
                        <div className={"document"}>
                            <span>Identificacion Oficial</span>
                            <i className={"material-icons"} onClick={() => {
                                setImage(company.officialDoccumentation.officialIdMedia.url);
                                setShowPictureModal(true);
                            }}>visibility</i>
                        </div>
                    }
                    {
                        company.officialDoccumentation.addressProofMedia.url &&
                        <div className={"document"}>
                            <span>Comprobante de domicilio</span>
                            <i className={"material-icons"} onClick={() => {
                                setImage(company.officialDoccumentation.addressProofMedia.url);
                                setShowPictureModal(true);
                            }}>visibility</i>
                        </div>
                    }
                    {
                        company.officialDoccumentation.addressPicture1.url &&
                        <div className={"document"}>
                            <span>Domicilio 1</span>
                            <i className={"material-icons"} onClick={() => {
                                setImage(company.officialDoccumentation.addressPicture1.url);
                                setShowPictureModal(true);
                            }}>visibility</i>
                        </div>
                    }
                    {
                        company.officialDoccumentation.addressPicture2.url &&
                        <div className={"document"}>
                            <span>Domicilio 2</span>
                            <i className={"material-icons"} onClick={() => {
                                setImage(company.officialDoccumentation.addressPicture2.url);
                                setShowPictureModal(true);
                            }}>visibility</i>
                        </div>
                    }
                    {
                        company.officialDoccumentation.addressPicture3.url &&
                        <div className={"document"}>
                            <span>Domicilio 3</span>
                            <i className={"material-icons"} onClick={() => {
                                setImage(company.officialDoccumentation.addressPicture3.url);
                                setShowPictureModal(true);
                            }}>visibility</i>
                        </div>
                    }
                    {
                        company.officialDoccumentation.menuServieMedia.url &&
                        <div className={"document"}>
                            <span>Menu</span>
                            <i className={"material-icons"} onClick={() => {
                                setImage(company.officialDoccumentation.menuServieMedia.url);
                                setShowPictureModal(true);
                            }}>visibility</i>
                        </div>
                    }
                    <Modal handleClose={setShowPictureModal} show={showPictureModal}>
                        <img className={"document-image"} src={image} alt={""}/>
                    </Modal>
                </>
            )
        }
    }

    const renderCompanyValidation = () => {
        // Only display validation buttons if company is IN_REVIEW = 1
        if (company.companyStatus === 1) {
            return (
                <div className={"company-validation"}>
                    <button className={"btn green"} onClick={() => setShowApproveCompanyModal(true)}>Aprobar</button>
                    <button className={"btn red"} onClick={() => setShowRejectCompanyModal(true)}>Rechazar</button>
                </div>
            );
        }
        return null;
    }

    const renderAccountActions = () => {
        if (company.companyStatus === 2) {
            return <button className={"btn orange"}
                           onClick={() => setShowSuspendCompanyModal(true)}>Suspender</button>
        } else if (company.companyStatus === 4) {
            return <button className={"btn green"}
                           onClick={() => setShowActivateCompanyModal(true)}>Restaurar</button>
        }
        return null;
    }

    const renderDeleteCompany = () => {
        return <button className={"btn red"} onClick={() => setShowDeleteCompanyModal(true)}>Eliminar</button>
    }

    const renderActions = () => {
        if (company.deletedAt === null || company.deletedAt === "0001-01-01T00:00:00") {
            return (
                <div className={"actions"}>
                    {renderCompanyValidation()}
                    {renderAccountActions()}
                    {renderDeleteCompany()}
                </div>
            );
        }
        return (
            <div className={"actions"}>
                <label>Compañia eliminada</label>
            </div>
        )
    }

    return (
        <div className={"company-modal"}>
            <h5>{company.name}</h5>
            <div className={"modal-group three-columns"}>
                <label className={"modal-group-title"}>Detalles</label>
                <div className={"modal-field"}>
                    <label>ID</label>
                    <span>{company.id}</span>
                </div>
                <div className={"modal-field"}>
                    <label>Correo electronico</label>
                    <span>{company.email}</span>
                </div>
                <div className={"modal-field"}>
                    <label>Telefono</label>
                    <span>{company.phone}</span>
                </div>
                <div className={"modal-field"}>
                    <label>Fecha de creación</label>
                    <span>{company.createdAt}</span>
                </div>
            </div>
            {
                company.address &&
                <div className={"modal-group three-columns"}>
                    <label className={"modal-group-title"}>Domicilio</label>
                    <div className={"modal-field"}>
                        <label>Calle</label>
                        <span>{company.address.name}</span>
                    </div>
                    <div className={"modal-field"}>
                        <label>C.P.</label>
                        <span>{company.address.postalcode}</span>
                    </div>
                    <div className={"modal-field"}>
                        <label>Estado</label>
                        <span>{company.address.state.name}</span>
                    </div>
                    <div className={"modal-field"}>
                        <label>País</label>
                        <span>{company.address.country.name}</span>
                    </div>
                    <div className={"modal-field"}>
                        <label>Descripción</label>
                        <span>{company.address.description}</span>
                    </div>
                </div>
            }
            {
                company.owner &&
                <div className={"modal-group three-columns"}>
                    <label className={"modal-group-title"}>Propietario</label>
                    <div className={"modal-field"}>
                        <label>Nombre(s)</label>
                        <span>{company.owner.firstname}</span>
                    </div>
                    <div className={"modal-field"}>
                        <label>Apellido(s)</label>
                        <span>{company.owner.lastname}</span>
                    </div>
                    <div className={"modal-field"}>
                        <label>Teléfono</label>
                        <span>{company.owner.phone}</span>
                    </div>
                    <div className={"modal-field"}>
                        <label>Correo electrónico</label>
                        <span>{company.owner.email}</span>
                    </div>
                </div>
            }
            <div className={"modal-section"}>
                <div className={"modal-group documents"}>
                    <label className={"modal-group-title"}>Documentacion</label>
                    {renderedPictures()}
                </div>
                <div className={"modal-group"}>
                    <label className={"modal-group-title"}>Acciones</label>
                    {renderActions()}
                </div>
            </div>
            <Modal handleClose={setShowApproveCompanyModal} show={showApproveCompanyModal}>
                <h5>Aprobar compañia</h5>
                <button className={"btn green"} onClick={() => handleCompanyStatus(2)}>Confirmar</button>
            </Modal>
            <Modal handleClose={setShowRejectCompanyModal} show={showRejectCompanyModal}>
                <h5>Rechazar compañia</h5>
                <textarea className={"text-area commentary"} value={commentary}
                          onChange={event => setCommentary(event.target.value)} placeholder={"Comentario"}
                          maxLength={255}/>
                <button className={"btn red"} onClick={() => handleCompanyStatus(3)}>Confirmar</button>
            </Modal>
            <Modal handleClose={setShowActivateCompanyModal} show={showActivateCompanyModal}>
                <h5>Activar compañia</h5>
                <button className={"btn green"} onClick={() => handleActivateSuspendCompany(1)}>Confirmar</button>
            </Modal>
            <Modal handleClose={setShowSuspendCompanyModal} show={showSuspendCompanyModal}>
                <h5>Suspender compañia</h5>
                <button className={"btn orange"} onClick={() => handleActivateSuspendCompany(2)}>Confirmar</button>
            </Modal>
            <Modal handleClose={setShowDeleteCompanyModal} show={showDeleteCompanyModal}>
                <h5>Eliminar compañia</h5>
                <button className={"btn red"} onClick={() => handleDeleteCompany()}>Confirmar</button>
            </Modal>
        </div>
    );
}

export default CompanyModal;