import React, { useEffect, useState } from "react";
import { format } from 'date-fns';
import currency from "currency.js";
import DatePicker from "../../components/DatePicker/DatePicker";
import Filter from "../../components/Filter/Filter";
import Table from "../../components/Table/Table";
import Pagination from "../../components/Pagination/Pagination";
import Dropdown from "../../components/Dropdown/Dropdown";
import Modal from "../../components/Modal/Modal";
import Insights from "../../api/Insights";

function DepositsList() {
    const [option, setOption] = useState({});
    const [receiver, setReceiver] = useState({});
    const [sender, setSender] = useState({});
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [params, setParams] = useState({ currentPage: 0, offset: 10 });
    const [buyerUsers, setBuyerUsers] = useState([]);
    const [userSellers, setUserSellers] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [buyersInitialized, setBuyersInitialized] = useState(false);
    const [sellersInitialized, setSellersInitialized] = useState(false);
    const [pages, setPages] = useState(0);
    const [total, setTotal] = useState("$0.00");
    const [payee, setPayee] = useState("");
    const [userDetails, setUserDetails] = useState({});
    const [showUserModal, setShowUserModal] = useState(false);

    useEffect(() => {
        setOption(options[0]);
    }, []);

    useEffect(() => {
        setStartDate("");
        setEndDate("");
        setSender({});
        setReceiver({});
    }, [option]);

    useEffect(() => {
        if (transactions.length > 0 && (!buyersInitialized || !sellersInitialized)) {
            const uniqueBuyers = transactions
                .map(transaction => transaction.buyerUser)
                .filter((user, index, self) => self.findIndex(u => u.id === user.id) === index);
            const uniqueSellers = transactions
                .map(transaction => transaction.userSeller)
                .filter((user, index, self) => self.findIndex(u => u.id === user.id) === index);
            
            if (!buyersInitialized) {
                setBuyerUsers(uniqueBuyers);
                setBuyersInitialized(true);
            }

            if (!sellersInitialized) {
                setUserSellers(uniqueSellers);
                setSellersInitialized(true);
            }
        }
    }, [transactions, buyersInitialized, sellersInitialized]);

    useEffect(() => {
        handleSearch();
        calculateTotal();
    }, [params]);

    useEffect(() => {
        const newParams = { ...params, currentPage: 0, offset: 10 };

        if (sender.id) {
            newParams.sellerUserId = sender.id;
        } else {
            delete newParams.sellerUserId;
        }

        if (receiver.id) {
            newParams.buyerUserId = receiver.id;
        } else {
            delete newParams.buyerUserId;
        }

        setParams(newParams);
    }, [sender, receiver]);

    const reset = () => {
        setParams({
            currentPage: 0,
            offset: 10
        });
        setOption(options[0])
    }

    const senderOptions = userSellers.map(seller => ({
        id: seller.id,
        text: `${seller.firstname} ${seller.lastname}`
    }));

    const receiverOptions = buyerUsers.map(buyer => ({
        id: buyer.id,
        text: `${buyer.firstname} ${buyer.lastname}`
    }));

    const handleParams = () => {
        const newParams = { ...params, currentPage: 0, offset: 10 };

        switch (option.id) {
            case 1:
                if (startDate) {
                    const startDateArr = startDate.split("-").map(value => parseInt(value));
                    const date1 = new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2]);
                    const date2 = new Date(new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2]).setDate(date1.getDate() + 1));
                    newParams.startDate = date1.toISOString();
                    newParams.endDate = date2.toISOString();
                }
                break;
            case 2:
                if (startDate) newParams.startDate = new Date(startDate).toISOString();
                if (endDate) newParams.endDate = new Date(endDate).toISOString();
                break;
            case 3:
                newParams.buyerUserId = payee;
                break;
            case 4:
                if (sender.id) newParams.sellerUserId = sender.id;
                break;
            case 5:
                if (receiver.id) newParams.buyerUserId = receiver.id;
                break;
            case 6:
                newParams.sellerUserId = sender.id;
                break;
        }

        setParams(newParams);
    }

    const handleSearch = async () => {
        const transactions = await Insights.transactions(params);
        setTransactions(transactions);
        setPages(await Insights.transactionsPagination(params));
    }

    const calculateTotal = async () => {
        const transactions = await Insights.transactions({
            startDate: params.startDate,
            endDate: params.endDate
        });

        let total = currency(0);
        transactions.forEach(transaction => {
            total = total.add(transaction.commissionAmount);
        });
        setTotal(total.format());
    }

    const options = [
        {
            id: 1,
            text: "Fecha",
            component: <DatePicker key={"1"} value={startDate} setValue={setStartDate} placeholder={"Elegir Fecha"}
                searchButton={true} handleSearch={handleParams} />
        },
        {
            id: 2,
            text: "Rango de Fecha",
            component: (
                <React.Fragment key={"2"}>
                    <DatePicker value={startDate} setValue={setStartDate} placeholder={"Elegir Fecha Inicial"} />
                    <DatePicker value={endDate} setValue={setEndDate} placeholder={"Elegir Fecha Final"}
                        searchButton={true} handleSearch={handleParams} />
                </React.Fragment>
            )
        },
    ];

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'HH:mm:ss  dd/MM/yyyy');
    };

    const tableConfig = [
        {
            header: "ID Transacción",
            content: item => {
                return item.id;
            }
        },
        {
            header: "Hora",
            content: item => {
                return formatDate(item.createdAt);
            }
        },
        {
            header: "Monto",
            content: item => {
                const amount = currency(item.cryptoAmount);
                return `${amount.format()} ${item.cryptoCurrency.currency}`;
            }
        },
        {
            header: "Emisor",
            content: item => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap:'10px' }}>
                        <span>{item.userSeller.firstname + ' ' + item.userSeller.lastname}</span>
                        <i className={"material-icons"} onClick={() => {
                            setUserDetails(item.userSeller);
                            setShowUserModal(true);
                        }}>visibility</i>
                    </div>
                );
            }
        },
        {
            header: "Receptor",
            content: item => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap:'10px' }}>
                        <span>{item.buyerUser.firstname + ' ' + item.buyerUser.lastname}</span>
                        <i className={"material-icons"} onClick={() => {
                            setUserDetails(item.buyerUser);
                            setShowUserModal(true);
                        }}>visibility</i>
                    </div>
                );
            }
        },
        {
            header: "Comisión",
            content: item => {
                const amount = currency(item.commissionAmount);
                return `${amount.format()} ${item.fiatCurrency.iso}`;
            }
        }
    ];

    return (
        <div className={"deposits-list"}>
            <Filter text={"Periodo"} options={options} option={option} setOption={setOption} resetFilter={reset}>
                {options.map(option => option.component)}
            </Filter>
            <div style={{ position: "absolute", display: 'flex', right: '200px', gap: '20px' }}>
                <Dropdown
                    text="Seleccionar Emisor"
                    options={senderOptions}
                    value={sender}
                    handleValue={setSender}
                    searchButton={false}
                />
                <Dropdown
                    text="Seleccionar Receptor"
                    options={receiverOptions}
                    value={receiver}
                    handleValue={setReceiver}
                    searchButton={false}
                />
            </div>
            <div className={"deposits-amount"}>
                <label>Comisión total</label>
                <span>{total} MXN</span>
            </div>
            <Table config={tableConfig} data={transactions} />
            <Pagination page={params.currentPage} setPage={(value) => setParams({ ...params, currentPage: value })} total={pages} />
            <Modal handleClose={setShowUserModal} show={showUserModal}>
            <div className="transaction-modal">
                    <h5>Detalles del Usuario</h5>
                    <div className={"modal-field"}>
                    <label>Nombre</label>
                    <span>{userDetails.firstname} {userDetails.lastname}</span>
                    </div>
                    <div className={"modal-field"}>
                    <label>Correo</label>
                    <span>{userDetails.email}</span>
                    </div>
                    <div className={"modal-field"}>
                    <label>Teléfono</label>
                    <span>{userDetails.phone}</span>
                    </div>
                    <div className={"modal-field"}>
                    <label>Primera transacción</label>
                    <span>Primera transacción: {userDetails.email}</span>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default DepositsList;
