import React, { useEffect, useState } from "react";
import { format } from 'date-fns';
import Insights from "../../api/Insights";

import DatePicker from "../../components/DatePicker/DatePicker";
import SearchField from "../../components/SearchField/SearchField";
import Dropdown from "../../components/Dropdown/Dropdown";
import Filter from "../../components/Filter/Filter";
import Table from "../../components/Table/Table";
import Pagination from "../../components/Pagination/Pagination";
import Modal from "../../components/Modal/Modal";
import TransactionModal from "./TransactionModal";
import currency from "currency.js";

function TransactionList() {
    const [show, setShow] = useState(false);
    const [transaction, setTransaction] = useState({});
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [transactionId, setTransactionId] = useState("");
    const [payee, setPayee] = useState("");
    const [option, setOption] = useState({});
    const [pages, setPages] = useState(0);
    const [transactions, setTransactions] = useState([]);
    const [receiver, setReceiver] = useState({});
    const [sender, setSender] = useState({});
    const [buyerUsers, setBuyerUsers] = useState([]);
    const [userSellers, setUserSellers] = useState([]);
    const [buyersInitialized, setBuyersInitialized] = useState(false);
    const [sellersInitialized, setSellersInitialized] = useState(false);
    const [params, setParams] = useState({
        currentPage: 0,
        offset: 10
    });
    const [userDetails, setUserDetails] = useState({});
    const [showUserModal, setShowUserModal] = useState(false);

    useEffect(() => {
        setOption(options[0]);
    }, []);

    useEffect(() => {
        if (transactions.length > 0 && (!buyersInitialized || !sellersInitialized)) {
            const uniqueBuyers = transactions
                .map(transaction => transaction.buyerUser)
                .filter((user, index, self) => self.findIndex(u => u.id === user.id) === index);
            const uniqueSellers = transactions
                .map(transaction => transaction.userSeller)
                .filter((user, index, self) => self.findIndex(u => u.id === user.id) === index);
            
            if (!buyersInitialized) {
                console.log(uniqueBuyers)
                setBuyerUsers(uniqueBuyers);
                setBuyersInitialized(true);
            }

            if (!sellersInitialized) {
                setUserSellers(uniqueSellers);
                setSellersInitialized(true);
            }
        }
    }, [transactions, buyersInitialized, sellersInitialized]);
    
    
    // Clear state when selected option changes
    useEffect(() => {
        setStartDate("");
        setEndDate("");
        setTransactionId("");
        setPayee("");
        setSender({});
        setReceiver({});
    }, [option]);

    // Update params when page changes
    useEffect(() => {
        handleSearch();
    }, [params]);

    useEffect(() => {
        const timer = setTimeout(() => {
            handleSearch();
        }, 200);

        return () => clearTimeout(timer);
    }, [params]);

    useEffect(() => {
        if (sender.id) {
            setParams((prevParams) => ({
                ...prevParams,
                sellerUserId: sender.id,
                currentPage: 0,
                offset: 10
            }));
        }
    }, [sender]);

    useEffect(() => {
        if (receiver.id) {
            setParams((prevParams) => ({
                ...prevParams,
                buyerUserId: receiver.id,
                currentPage: 0,
                offset: 10
            }));
        }
    }, [receiver]);

    const reset = () => {
        setParams({
            currentPage: 0,
            offset: 10
        });
        setOption(options[0]);
    }

    const handleParams = () => {
        switch (option.id) {
            case 1:
                const startDateArr = startDate.split("-").map(value => parseInt(value));
                const date1 = new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2]);
                const date2 = new Date(new Date(startDateArr[0], startDateArr[1] - 1, startDateArr[2]).setDate(date1.getDate() + 1));

                setParams({
                    startDate: date1.getFullYear() + "-" + (date1.getMonth() + 1) + "-" + date1.getDate() + "T00:00:00",
                    endDate: date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate() + "T00:00:00",
                    currentPage: 0,
                    offset: 10,
                });
                break;

            case 2:
                setParams({
                    startDate: startDate,
                    endDate: endDate,
                    currentPage: 0,
                    offset: 10
                });
                break;

            case 3:
                setParams({
                    orderId: transactionId,
                    currentPage: 0,
                    offset: 10
                });
                break;

            case 4:
                setParams({
                    buyerUserId: payee,
                    currentPage: 0,
                    offset: 10
                });
                break;

            case 5:
                setParams({
                    sellerUserId: sender.id,
                    currentPage: 0,
                    offset: 10
                });
                break;

            default:
                return;
        }
    }

    const handleSearch = async () => {
        setPages(await Insights.transactionsPagination(params));
        setTransactions(await Insights.transactions(params));
    }

    const senderOptions = userSellers.map(seller => ({
        id: seller.id,
        text: `${seller.firstname} ${seller.lastname}`
    }));

    const receiverOptions = buyerUsers.map(buyer => ({
        id: buyer.id,
        text: `${buyer.firstname} ${buyer.lastname}`
    }));

    // Set Filter options
    const options = [
        {
            id: 1,
            text: "Fecha",
            component: <DatePicker key={"1"} value={startDate} setValue={setStartDate} placeholder={"Elegir Fecha"}
                searchButton={true} handleSearch={handleParams} />
        },
        {
            id: 2,
            text: "Rango de Fecha",
            component: <React.Fragment key={"2"}>
                <DatePicker value={startDate} setValue={setStartDate} placeholder={"Elegir Fecha Inicial"} />
                <DatePicker value={endDate} setValue={setEndDate} placeholder={"Elegir Fecha Final"}
                    searchButton={true} handleSearch={handleParams} />
            </React.Fragment>
        },
        {
            id: 3,
            text: "No. de Transacción",
            component: <SearchField key={"3"} value={transactionId} setValue={setTransactionId}
                placeholder={"No. de Transacción"}
                searchButton={true} handleSearch={handleParams} />
        },
    ];

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'HH:mm:ss  dd/MM/yyyy');
    };

    const tableConfig = [
        {
            header: "ID Transacción",
            content: item => {
                return item.id;
            }
        },
        {
            header: "Hora",
            content: item => {
                return formatDate(item.createdAt);
            }
        },
        {
            header: "Monto",
            content: item => {
                const amount = currency(item.cryptoAmount);
                return `${amount.format()} ${item.cryptoCurrency.currency}`;
            }
        },
          {
            header: "Emisor",
            content: item => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap:'10px' }}>
                        <span>{item.userSeller.firstname + ' ' + item.userSeller.lastname}</span>
                        <i className={"material-icons"} onClick={() => {
                            setUserDetails(item.userSeller);
                            setShowUserModal(true);
                        }}>visibility</i>
                    </div>
                );
            }
        },
        {
            header: "Receptor",
            content: item => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap:'10px' }}>
                        <span>{item.buyerUser.firstname + ' ' + item.buyerUser.lastname}</span>
                        <i className={"material-icons"} onClick={() => {
                            setUserDetails(item.buyerUser);
                            setShowUserModal(true);
                        }}>visibility</i>
                    </div>
                );
            }
        },
        {
            header: "Detalles",
            content: (item) => <i className={"material-icons"} onClick={() => {
                setTransaction(item);
                setShow(true);
            }}>receipt_long</i>
        }
    ];

    return (
        <div className={"transaction-list"}>
            <Filter
                text={"Buscar por"}
                options={options}
                option={option}
                setOption={setOption}
                resetFilter={reset}
            >
                {options.map(option => option.component)}
            </Filter>
            <div style={{ position: "absolute", display: 'flex', right: '30px', gap: '20px' }}>
                <Dropdown
                    text="Seleccionar Emisor"
                    options={senderOptions}
                    value={sender}
                    handleValue={setSender}
                    searchButton={false}
                />
                <Dropdown
                    text="Seleccionar Receptor"
                    options={receiverOptions}
                    value={receiver}
                    handleValue={setReceiver}
                    searchButton={false}
                />
            </div>
            <Table config={tableConfig} data={transactions} />
            <Pagination page={params.currentPage} setPage={(value) => setParams({ ...params, currentPage: value })}
                total={pages} />
            <Modal handleClose={setShow} show={show}>
                <TransactionModal transaction={transaction} />
            </Modal>
            <Modal handleClose={setShowUserModal} show={showUserModal}>
                <div className="transaction-modal">
                    <h5>Detalles del Usuario</h5>
                    <div className={"modal-field"}>
                    <label>Nombre</label>
                    <span>{userDetails.firstname} {userDetails.lastname}</span>
                    </div>
                    <div className={"modal-field"}>
                    <label>Correo</label>
                    <span>{userDetails.email}</span>
                    </div>
                    <div className={"modal-field"}>
                    <label>Teléfono</label>
                    <span>{userDetails.phone}</span>
                    </div>
                    <div className={"modal-field"}>
                    <label>Primera transacción</label>
                    <span>Primera transacción: {userDetails.email}</span>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default TransactionList;
