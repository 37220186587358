import "./Transactions.css";

import React, {useState} from "react";

import TransactionList from "./TransactionList";
import TransactionReport from "./TransactionReport";
import TransactionCharts from "./TransactionCharts";

function Transactions() {
    // State
    const [activeTab, setActiveTab] = useState("txn");

    const classText = (value) => {
        const text = "tab-item";

        if (activeTab === value) {
            return text + " active";
        }
        return text;
    }

    const content = () => {
        switch (activeTab) {
            case "txn":
                return <TransactionList/>;
            case "reports":
                return <TransactionReport/>;
        }
    }

    return (
        <div className={"transactions section"}>
            <div className="tab-bar">
                <div className={classText("txn")} onClick={() => setActiveTab("txn")}>
                    <span>Transacciones</span>
                </div>
                <div className={classText("reports")} onClick={() => setActiveTab("reports")}>
                    <span>Reportes</span>
                </div>
            </div>
            {content()}
        </div>
    );
}

export default Transactions