
import {useState} from "react";
import RecomendationsList from "./RecomendationsList";

function Recomendations() {
    const [activeTab, setActiveTab] = useState("company");

    const classText = (value) => {
        const text = "tab-item";

        if (activeTab === value) {
            return text + " active";
        }
        return text;
    }

    const content = () => {
        switch (activeTab) {
            case "company":
                return <RecomendationsList/>
            break;
        }
    }

    return (
        <div className={"business section"}>
            <div className={"tab-bar"}>
                <div className={classText("company")} onClick={() => setActiveTab("company")}>
                    <span>Recomendaciones</span>
                </div>

            </div>
            {content()}
        </div>
    );
}

export default Recomendations;