import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    currentPage: "control-panel"
}

export const DashboardSlice = createSlice({
    name: "dashboard",
    initialState: initialState,
    reducers: {
        changePage: (state, action) => {
            state.currentPage = action.payload;
        }
    }
});

export const {changePage} = DashboardSlice.actions;
export default DashboardSlice.reducer;