import React, { useState } from 'react'; // Import useState
import './Menu.css';
import CrypPayLogo from '../../images/Icon_CrypPayLarge.png';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { changePage } from '../../features/Dashboard/DashboardSlice';

function Menu() {
  // Hooks
  const dispatch = useDispatch();

  // Redux state
  const currentPage = useSelector((state) => state.dashboard.currentPage);

  const isActive = (page) => {
    return currentPage === page ? 'active' : '';
  };

  const handleLinkClick = (page) => {
    dispatch(changePage(page));
  };

  const [isSubMenuOpen, setSubMenuOpen] = useState(false);

  const toggleSubMenu = () => {
    setSubMenuOpen(!isSubMenuOpen);
  };

  return (
    <div className={'menu'}>
      <div className={'menu-header'}>
        <img src={CrypPayLogo} alt={''} />
      </div>
      <Link
        className={'menu-element ' + isActive('control-panel')}
        to={'/'}
        onClick={() => handleLinkClick('control-panel')}
      >
        <i className={'material-icons'}>grid_view</i>
        <span>Panel de control</span>
      </Link>
      <Link
        className={'menu-element ' + isActive('transactions')}
        to={'/transactions'}
        onClick={() => handleLinkClick('transactions')}
      >
        <i className={'material-icons'}>currency_exchange</i>
        <span>Transacciones</span>
      </Link>
      <Link
        className={'menu-element ' + isActive('deposits')}
        to={'/deposits'}
        onClick={() => handleLinkClick('deposits')}
      >
        <i className={'material-icons'}>account_balance</i>
        <span>Depósitos</span>
      </Link>
      <Link
        className={'menu-element ' + isActive('business')}
        to={'/business'}
        onClick={() => handleLinkClick('business')}
      >
        <i className={'material-icons'}>work</i>
        <span>CrypPay Business</span>
      </Link>
      <Link
        className={'menu-element ' + isActive('personal')}
        to={'/personal'}
        onClick={() => handleLinkClick('personal')}
      >
        <i className={'material-icons'}>person</i>
        <span>CrypPay Personal</span>
      </Link>

      <Link
        className={'menu-element ' + isActive('recomendations')}
        to={'/recomendations'}
        onClick={() => handleLinkClick('recomendations')}
      >
        <i className={'material-icons'}>article</i>
        <span>Recomendaciones</span>
      </Link>
      

      <div className={'menu-element'}  onClick={toggleSubMenu}>
        <i className={'material-icons'} onClick={toggleSubMenu}>insights</i>
        <span>Gráficos</span>
        <div className='arrow'>
        <i className={`material-icons ${isSubMenuOpen ? 'sub-menu-icon-open' : 'sub-menu-icon'}`}>
          {isSubMenuOpen ? 'expand_less' : 'expand_more'}
        </i>
        </div>
      </div>
      
      {isSubMenuOpen && (
        <div className={'sub-menu'}>
          <Link
            className={'menu-element ' + isActive('transactions_graphics')}
            to={'/transactions_graphics'}
            onClick={() => handleLinkClick('transactions_graphics')}
          >
            <i className={'material-icons'}>price_check</i>
            <span>Transacciones</span>
          </Link>
          <Link
            className={'menu-element ' + isActive('deposits_graphics')}
            to={'/deposits_graphics'}
            onClick={() => handleLinkClick('deposits_graphics')}
          >
            <i className={'material-icons'}>local_atm</i>
            <span>Depósitos</span>
          </Link>
          <Link
            className={'menu-element ' + isActive('business_graphics')}
            to={'/business_graphics'}
            onClick={() => handleLinkClick('business_graphics')}
          >
            <i className={'material-icons'}>business</i>
            <span>Business</span>
          </Link>
          <Link
            className={'menu-element ' + isActive('personal_graphics')}
            to={'/personal_graphics'}
            onClick={() => handleLinkClick('')}
          >
            <i className={'material-icons'}>person</i>
            <span>Personal</span>
          </Link>
          <Link
            className={'menu-element ' + isActive('transactors')}
            to={'/transactors'}
            onClick={() => handleLinkClick('transactors')}
          >
            <i className={'material-icons'}>person</i>
            <span>Transactors</span>
          </Link>
        </div>
      )}

    </div>
  );
}

export default Menu;
