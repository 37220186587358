import CrypPay from "./CrypPay";

class Auth {
    login = async (email, password) => {
        const response= await CrypPay.post("/Auth/Login", {
            email: email,
            password: password,
            appBuildVersion: 5,
            os: 1,
            appType:1
        });

        return response.data.response;
    }
}

export default new Auth();