import "./Pagination.css";
import {useEffect, useState} from "react";

function Pagination({page, setPage, total}) {
    const [text, setText] = useState(page + 1);

    // Update input text value if page changes
    useEffect(() => {
        setText(page + 1);
    }, [page]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (isNaN(text) || (parseInt(text) < 1 || parseInt(text) > total)) {
            setText(page + 1);
            return;
        }

        handlePage(parseInt(text) - 1);
    }

    const handlePage = (value) => {
        console.log(value);
        setPage(value);
    }

    const isDisabled = (value) => {
        return (value === page);
    }

    return (
        <form className={"pagination"} onSubmit={handleSubmit}>
            <button type={"button"} onClick={() => handlePage(0)} disabled={isDisabled(0)}>
                <i className={"material-icons"}>first_page</i>
            </button>
            <button type={"button"} onClick={() => handlePage(page - 1)} disabled={isDisabled(0)}>
                <i className={"material-icons"}>chevron_left</i>
            </button>
            <div>
                <input className={"browser-default"} type={"text"} value={text}
                       onChange={event => setText(event.target.value)}/> of {total}
            </div>
            <button type={"button"} onClick={() => handlePage(page + 1)} disabled={isDisabled(total - 1)}>
                <i className={"material-icons"}>chevron_right</i>
            </button>
            <button type={"button"} onClick={() => handlePage(total - 1)} disabled={isDisabled(total - 1)}>
                <i className={"material-icons"}>last_page</i>
            </button>
        </form>
    );
}

export default Pagination