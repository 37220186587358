class Dates {
    getDateInISOString = (year, month, day) => {
        return new Date(year, month, day).toISOString();
    }

    getYears = () => {
        const currentYear = new Date().getFullYear(), years = [];
        for (let i = currentYear; i >= 2022; i--) {
            years.push({id: i, text: i});
        }
        return years;
    }

    getMonthName = (monthIndex) => {
        switch (monthIndex) {
            case 0: return "Enero";
            case 1: return "Febrero";
            case 2: return "Marzo";
            case 3: return "Abril";
            case 4: return "Mayo";
            case 5: return "Junio";
            case 6: return "Julio";
            case 7: return "Agosto";
            case 8: return "Septiembre";
            case 9: return "Octubre";
            case 10: return "Noviembre";
            case 11: return "Diciembre";
        }
    }
}

export default new Dates();