import {useEffect, useState} from "react";
import SearchField from "../../components/SearchField/SearchField";
import Company from "../../api/Company";
import Filter from "../../components/Filter/Filter";
import Table from "../../components/Table/Table";
import Pagination from "../../components/Pagination/Pagination";
import Modal from "../../components/Modal/Modal";
import CompanyModal from "./CompanyModal";

function CompaniesList() {
    const [option, setOption] = useState({});

    const [name, setName] = useState("");
    const [id, setId] = useState("");
    const [email, setEmail] = useState("");
    const [params, setParams] = useState({currentPage: 0, offset:10});

    const [company, setCompany] = useState({});
    const [companies, setCompanies] = useState([]);
    const [pages, setPages] = useState(0);

    const [show, setShow] = useState(false);

    // Set option to first element in options array when component is loaded for the first time.
    useEffect(() => {
        setOption(options[0]);
    }, []);

    // Clear state when selected option changes
    useEffect(() => {
        setName("");
        setId("")
        setEmail("");
    }, [option]);

    // Request companies when params change
    useEffect(() => {
        handleSearch();
    }, [params]);

    const reset = () => {
        setParams({
            currentPage: 0,
            offset:10
        });
        setOption(options[0])
    }

    const handleParams = () => {
        switch (option.id) {
            case 1: // Company name
                setParams({
                    name: name,
                    currentPage: 0,
                    offset:10,
                });
                break;
            case 2: // Company email
                setParams({
                    email: email,
                    currentPage: 0,
                    offset:10
                });
                break;
            case 3: // Company ID
                setParams({
                    id: id,
                    currentPage: 0,
                    offset:10
                });
                break;
        }
    }

    const handleSearch = async () => {
        setPages(await Company.getListPagination(params));
        setCompanies(await Company.getList(params));
    }

    const options = [
        {
            id: 1,
            text: "Nombre",
            component: <SearchField key={"1"} value={name} setValue={setName} placeholder={"Nombre de compañia"}
                                    searchButton={true} handleSearch={handleParams}/>
        },
        {
            id: 2,
            text: "E-mail",
            component: <SearchField key={"2"} type={"email"} value={email} setValue={setEmail} placeholder={"E-mail"}
                                    searchButton={true} handleSearch={handleParams}/>
        },
        {
            id: 3,
            text: "ID",
            component: <SearchField key={"3"} value={id} setValue={setId} placeholder={"ID de compañia"}
                                    searchButton={true} handleSearch={handleParams}/>
        }
    ];

    // Set table configuration
    const tableConfig = [
        {
            header: "ID",
            content: item => item.id
        },
        {
            header: "Nombre",
            content: item => item.name
        },
        {
            header: "Email",
            content: item => item.email
        },
        {
            header: "Fecha de creacion",
            content: item => item.createdAt
        },
        {
            header: "Detalles",
            content: item => {
                return <i className={"material-icons"} onClick={() => {
                    setCompany(item);
                    setShow(true);
                }}>business</i>
            }
        }
    ]

    return (
        <div className={"companies-list"}>
            <Filter text={"Buscar por"} options={options} option={option} setOption={setOption} resetFilter={reset}>
                {options.map(option => option.component)}
            </Filter>
            <Table config={tableConfig} data={companies}/>
            <Pagination page={params.currentPage} setPage={(value) => setParams({...params, currentPage: value})} total={pages}/>
            <Modal handleClose={setShow} show={show}>
                <CompanyModal companyId={company.id} displayed={show} handleClose={setShow}/>
            </Modal>
        </div>
    );
}

export default CompaniesList;