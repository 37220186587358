import CrypPay from "./CrypPay";

class Insights {
    checkToken(){
        CrypPay.interceptors.response.use(
            response => response,
            error => {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem("auth");
                    window.location.href = "/login";
                }
                return Promise.reject(error);
            }
        );
    }

    
    transactions = async ({startDate, endDate, orderId, buyerUserId, sellerUserId, currentPage, offset}) => {
        this.checkToken()
        const response = await CrypPay.get("/Insights/Transactions", {
            params: {
                startDate: startDate,
                endDate: endDate,
                orderId: orderId,
                buyerUserId: buyerUserId,
                sellerUserId: sellerUserId,
                currentPage: currentPage,
                offset: offset
            }
        });

        return response.data.response;
    }

    transactionsPagination = async ({startDate, endDate, orderId, buyerUserId, offset}) => {
        this.checkToken()
        const response = await CrypPay.get("/Insights/TransactionsPagination", {
            params: {
                startDate: startDate,
                endDate: endDate,
                orderId: orderId,
                buyerUserId: buyerUserId,
                splitBy: offset
            }
        });

        return response.data.response;
    }

    exportData = async (date, period, content) => {
        this.checkToken()
        const response = await CrypPay.get("/Insights/ExportData", {
            params: {
                date: date,
                period: period,
                content: content
            }
        });
        console.log(response.data.response)
        return response.data.response;
    }

    charts = async (date, period) => {
        this.checkToken()
        const response = await CrypPay.get("/Insights/TransactionsGraph", {
            params: {
                date: date,
                period: period
            }
        });
        console.log(response.data.response)

        return response.data.response;
    }

    accountsGraph = async (date, period, role) => {
        this.checkToken()
        const response = await CrypPay.get("/Insights/AccountsGraph", {
            params: {
                date: date,
                period: period,
                role: 3,
            }
        });
        return response.data.response;
    }

    depositsGraph = async (date, period, role) => {
        this.checkToken()
        const response = await CrypPay.get("/Insights/DepositsGraph", {
            params: {
                date: date,
                period: period,
                role: role, 
            }
        });
        console.log(response.data.response)
        return response.data.response;
    }

    transactorsGraph = async (date, period, priority) => {
        this.checkToken()
        const response = await CrypPay.get("/Insights/TransactorsGraph", {
            params: {
                date: date,
                period: period,
                storesLimit: 50,
                priority: priority
            }
        });
        return response.data.response;
    }

    personalGraph = async (date, period, role) => {
        this.checkToken()
        const response = await CrypPay.get("/Insights/AccountsGraph", {
            params: {
                date: date,
                period: period,
                role: 4, 
            }
        });
        return response.data.response;
    }
}




export default new Insights();