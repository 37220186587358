import "./Deposits.css";

import {useState} from "react";

import DepositsList from "./DepositsList";
import DepositsReport from "./DepositsReport";

function Deposits() {
    const [activeTab, setActiveTab] = useState("list");

    const classText = (value) => {
        const text = "tab-item";

        if (activeTab === value) {
            return text + " active";
        }
        return text;
    }

    const content = () => {
        switch (activeTab) {
            case "list":
                return <DepositsList/>;
            case "reports":
                return <DepositsReport/>;
        }
    }

    return (
        <div className={"deposits section"}>
            <div className={"tab-bar"}>
                <div className={classText("list")} onClick={() => setActiveTab("list")}>
                    <span>Transacciones</span>
                </div>
                <div className={classText("reports")} onClick={() => setActiveTab("reports")}>
                    <span>Reportes</span>
                </div>
            </div>
            {content()}
        </div>
    )
}

export default Deposits;