import { useEffect, useState } from "react";
import SearchField from "../../components/SearchField/SearchField";
import Recommendations from "../../api/Recommendations";
import Filter from "../../components/Filter/Filter";
import Table from "../../components/Table/Table";
import Pagination from "../../components/Pagination/Pagination";
import Modal from "../../components/Modal/Modal";

function RecomendationsList() {
    const [option, setOption] = useState({});
    const [name, setName] = useState("");
    const [id, setId] = useState("");
    const [params, setParams] = useState({ currentPage: 0, count: 10, businessName: '' });
    const [user, setUser] = useState({});
    const [clients, setClients] = useState([]);
    const [pages, setPages] = useState(0);
    const [show, setShow] = useState(false);

    const reset = () => {
        setParams({
            currentPage: 0,
            count: 10
        });
        setOption(options[0]);
    };

    useEffect(() => {
        fetchRecommendations();
    }, [params]);


    const fetchRecommendations = async () => {
        try {

            const recommendationData = await Recommendations.getRecommendations(params);
            setPages(await Recommendations.getListPagination(params));
            setClients(recommendationData);
        } catch (error) {
            console.error("Error fetching recommendations:", error);
        }
    };

    const handleParams = async () => {
        try {
            params.businessName = name
            const recommendationData = await Recommendations.getRecommendations(params);
            setPages(await Recommendations.getListPagination(params));
            setClients(recommendationData);
        } catch (error) {
            console.error("Error fetching recommendations:", error);
        }
    };

    const options = [
        {
            id: 1,
            text: "Nombre",
            component: <SearchField key={"1"} value={name} setValue={setName} placeholder={"Nombre de cliente"}
                searchButton={true} handleSearch={handleParams} />
        },

    ];

    const tableConfig = [
        {
            header: "ID",
            content: item => item.id
        },
        {
            header: "Nombre",
            content: item => item.businessName
        },
        {
            header: "Fecha de creacion",
            content: item => item.createdAt
        },
        {
            header: "Teléfono",
            content: item => item.phone
        },
        {
            header: "Coincidencias",
            content: (item) =>
                <MatchingRecommendations businessName={item.businessName} />,
        },
    ];

    const MatchingRecommendations = (params) => {
        const [matches, setMatches] = useState();

        useEffect(() => {
            const fetchMatches = async () => {
                try {
                    const recommendationData = await Recommendations.getCoincidences(
                       params
                    );
                    setMatches(recommendationData);
                } catch (error) {
                    console.error("Error fetching matches:", error);
                }
            };

            fetchMatches();
        }, [params]);

        return (
            <span>{matches}</span>
        );
    };

    return (
        <div className={"companies-list"}>
            <Filter text={"Buscar por"} options={options} option={option} setOption={setOption} resetFilter={reset}>
                {options.map(option => option.component)}
            </Filter>
            <Table config={tableConfig} data={clients} />
            <Pagination page={params.currentPage} setPage={(value) => setParams({ ...params, currentPage: value })}  total={pages} />
            <Modal handleClose={setShow} show={show}>
                {user && user.recommendedBy && (
                    <div className="company-modal">
                        <h5>Detalles</h5>
                        <div className="modal-group">
                            <label className={"modal-group-title"}>Recomendado Por:</label>
                            <p>Nombre: {user.recommendedBy.firstname} {user.recommendedBy.lastname}</p>
                            <p>Teléfono: {user.recommendedBy.phone} </p>
                            <p>Correo: {user.recommendedBy.email}</p>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
}

export default RecomendationsList;
