import "./DatePicker.css";

/**
 * Displays an input[@type='text'] element
 * @param value
 * @param setValue
 * @param placeholder
 * @param searchButton Set to true to include a search icon within the input
 * @param handleSearch
 * @returns {JSX.Element}
 * @constructor
 */
function DatePicker({value, setValue, placeholder, searchButton, handleSearch}) {
    return (
        <div className={"date-picker" + (searchButton ? " search" : "")}>
            <div className={"field"}>
                <span>{placeholder}</span>
                <input className={"browser-default"} type={"date"} value={value}
                       onChange={(event) => setValue(event.target.value)}/>
            </div>
            {searchButton && <i className={"material-icons search-button"} onClick={handleSearch}>search</i>}
        </div>
    );
}

export default DatePicker;