import "./ControlPanel.css";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import currency from "currency.js";
import Insights from "../../api/Insights";
import { changePage } from "../Dashboard/DashboardSlice";
import Chart from "react-apexcharts";
import DatePicker from "../../components/DatePicker/DatePicker";

function ControlPanel() {
    // Hooks
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // State
    const [transactionAmount, setTransactionAmount] = useState("$0.00");
    const [transactionAmountMX, setTransactionAmountMX] = useState("$0.00");
    const [depositsAmount, setDepositsAmount] = useState("$0.00");

    // State - Chart
    const [series, setSeries] = useState([{
        name: "Transacciones",
        data: []
    }]);

    const [chartOptions, setChartOptions] = useState({
        chart: {
            zoom: { enabled: false }
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        title: {
            text: "Transacciones del día",
            align: "left"
        },
        xaxis: {
            categories: [],
            labels: {
                formatter: function (value) {
                    return new Date(value).toLocaleTimeString();
                }
            }
        }
    });

    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().slice(0, 10));
    const [selectedSeries, setSelectedSeries] = useState([{
        name: "Transacciones",
        data: []
    }]);

    const [selectedChartOptions, setSelectedChartOptions] = useState({
        chart: {
            zoom: { enabled: false }
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        title: {
            text: "Transacciones del día seleccionado",
            align: "left"
        },
        xaxis: {
            categories: [],
            labels: {
                formatter: function (value) {
                    return new Date(value).toLocaleTimeString();
                }
            }
        }
    });

    const getAmount = async () => {
        try {
            const date = new Date();
            const startDate = new Date(date.setHours(0, 0, 0, 0)).toISOString();
            const endDate = new Date(date.setHours(23, 59, 59, 999)).toISOString();

            const transactions = await Insights.transactions({ startDate, endDate });

            let transactionTotal = currency(0);
            let transactionTotalMX = currency(0);
            let depositsTotal = currency(0);
            transactions.forEach(transaction => {
                transactionTotal = transactionTotal.add(transaction.cryptoAmount);
                transactionTotalMX = transactionTotalMX.add(transaction.fiatAmount);
                depositsTotal = depositsTotal.add(transaction.commissionAmount);
            });

            setTransactionAmount(transactionTotal.format());
            setTransactionAmountMX(transactionTotalMX.format());
            setDepositsAmount(depositsTotal.format());

            // Update chart data
            const categories = transactions.map(transaction => transaction.createdAt);
            const data = transactions.map(transaction => transaction.cryptoAmount);
            updateChartOptions(categories);
            updateChartSeries(data);
        } catch (e) {
            console.error(e);
        }
    }

    const getSelectedDateTransactions = async (date) => {
        try {
            const startDate = new Date(date).toISOString();
            const endDate = new Date(new Date(date).setHours(23, 59, 59, 999)).toISOString();
            
          
            const transactions = await Insights.transactions({ startDate, endDate });

            const categories = transactions.map(transaction => transaction.createdAt);
            const data = transactions.map(transaction => transaction.cryptoAmount);
            console.log("HA")
            setSelectedChartOptions(prevOptions => ({
                ...prevOptions,
                xaxis: {
                    ...prevOptions.xaxis,
                    categories: categories
                }
            }));

            setSelectedSeries([{
                name: "Transacciones",
                data: data
            }]);
        } catch (e) {
            console.error(e);
        }
    }

    const updateChartOptions = (categories) => {
        setChartOptions(prevOptions => ({
            ...prevOptions,
            xaxis: {
                ...prevOptions.xaxis,
                categories: categories
            }
        }));
    }

    const updateChartSeries = (data) => {
        setSeries([{
            name: "Transacciones",
            data: data
        }]);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            getAmount();
            getSelectedDateTransactions(selectedDate);
        }, 10000);
    
        return () => clearTimeout(timer);
    }, [getAmount]);

    useEffect(() => {
        const timer = setTimeout(() => {
            getSelectedDateTransactions(selectedDate);
        }, 100);
    
        return () => clearTimeout(timer);
    }, [selectedDate]);
    
    
  
 


    const handleNavigation = (value) => {
        dispatch(changePage(value));
        navigate(`/${value}`);
    }

    return (
        <div className={"control-panel section"}>
            <h5>Tu información al día de hoy</h5>
            <div className={"panel"}>
                <div className={"panel-element z-depth-1"}>
                    <div className={"panel-element-title"} onClick={() => handleNavigation("transactions")}>
                        <i className={"material-icons transaction-icon"}>currency_exchange</i>
                        <span>Transacciones</span>
                        <i className={"material-icons"}>navigate_next</i>
                    </div>
                    <div className={"panel-element-content"}>
                        <span className={"amount"}>{transactionAmount} <span style={{ fontSize: '18px' }}>usdt.</span></span>
                        <span className={"currency"}>Monto (MXN) {transactionAmountMX}</span>
                    </div>
                </div>
                <div className={"panel-element z-depth-1"}>
                    <div className={"panel-element-title"} onClick={() => handleNavigation("deposits")}>
                        <i className={"material-icons deposit-icon"}>account_balance</i>
                        <span>Depósitos</span>
                        <i className={"material-icons"}>navigate_next</i>
                    </div>
                    <div className={"panel-element-content"}>
                        <span className={"amount"}>{depositsAmount}</span>
                        <span className={"currency"}>Monto (MXN)</span>
                    </div>
                </div>
            </div>
            <div style={{display:'flex', justifyContent:'space-around', alignItems:'center', marginTop:'2rem'}}>
                <div st className={"chart"}>
                    <Chart type={"line"} width={"650"} options={chartOptions} series={series} />
                </div>
                <div className={"date-picker-chart"}>
                    <DatePicker value={selectedDate} setValue={setSelectedDate} placeholder={"Seleccionar Fecha"} />
                    <Chart type={"line"} width={"650"} options={selectedChartOptions} series={selectedSeries} />
                </div>

            </div>
        </div>
    );
}

export default ControlPanel;
