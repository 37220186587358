import "./Dropdown.css";

import {useEffect, useRef, useState} from "react";

/**
 * Returns a Dropdown JSX Element with the options set
 * @param text optional text to be displayed within the dropdown box
 * @param options array of options [{ id: Number, text: String}]
 * @param value active option
 * @param handleValue callback to change the active option
 * @param searchButton
 * @param handleSearch
 * @returns {JSX.Element}
 * @constructor
 */
function Dropdown({text, options, value, handleValue, searchButton, handleSearch}) {
    // Hooks
    const ref = useRef();

    // State
    const [expanded, setExpanded] = useState(false);

    // Add eventListener to know when a click is made outside filter dropdown
    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        }
    }, []);

    const handleClick = (event) => {
        if (!ref.current.contains(event.target)) {
            setExpanded(false);
        }
    }

    const renderedOptions = options.map(option => {
        return (
            <div key={option.id} className={"dropdown-item"} onClick={() => handleValue(option)}>
                <span>{option.text}</span>
            </div>
        );
    });

    return (
        <div className={"dropdown" + (searchButton ? " search" : "")}>
            <div className={"dropdown-field"} ref={ref} onClick={() => setExpanded(!expanded)}>
                <div className={"dropdown-label"}>
                    <span>{text}</span>
                    <span>{value.text}</span>
                </div>
                <i className={"material-icons"}>arrow_drop_down</i>
            </div>
            <div className={"dropdown-menu z-depth-1" + (expanded ? " expanded" : "")}>
                {renderedOptions}
            </div>
            {searchButton && <i className={"material-icons search-button"} onClick={handleSearch}>search</i>}
        </div>
    );
}

export default Dropdown;