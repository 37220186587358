import "./TransactionModal.css";

function TransactionModal({transaction}) {
    return (
        <div className={"transaction-modal"}>
            <h5>Detalles de transacción</h5>
            <div className={"modal-group"}>
                <div className={"modal-field"}>
                    <label>No. de Transacción</label>
                    <span>{transaction.id}</span>
                </div>
                <div className={"modal-field"}>
                    <label>Descripción</label>
                    <span>{transaction.description}</span>
                </div>
                <div className={"modal-field"}>
                    <label>Cantidad</label>
                    <span>{transaction.fiatAmount} {transaction.fiatCurrency ? transaction.fiatCurrency.iso : ""}</span>
                </div>
                <div className={"modal-field"}>
                    <label>Crypto moneda</label>
                    <span>{transaction.cryptoAmount} {transaction.cryptoCurrency ? transaction.cryptoCurrency.description : ""}</span>
                </div>
            </div>
            {
                // Render once store object is loaded
                transaction.store &&
                <div className={"modal-group"}>
                    <label>Negocio</label>
                    <div className={"modal-field"}>
                        <label>Nombre</label>
                        <span>{transaction.store.name}</span>
                    </div>
                    {
                        // Render once address object is loaded
                        transaction.store.address &&
                        <div className={"modal-field"}>
                            <label>Domicilio</label>
                            <span>
                                {transaction.store.address.name}, {transaction.store.address.postalcode}, {transaction.store.address.state.name}, {transaction.store.address.country.name}
                            </span>
                        </div>
                    }
                </div>
            }
        </div>
    )
        ;
}

export default TransactionModal;