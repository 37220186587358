import { createContext, useContext, useEffect, useMemo, useState } from "react";
import CrypPay from "../api/CrypPay";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(JSON.parse(localStorage.getItem("auth")));

  useEffect(() => {
    if (auth) {
      CrypPay.defaults.headers.common["Authorization"] = "Bearer " + auth.token;
      localStorage.setItem("auth", JSON.stringify(auth));
    } else {
      delete CrypPay.defaults.headers.common["Authorization"];
      localStorage.removeItem("auth");
    }
  }, [auth]);

  const contextValue = useMemo(
    () => ({
      auth,
      setAuth
    }),
    [auth]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
