import TransactionCharts from "../../Transactions/TransactionCharts";

 function TransactionsGraphic(){
    return(
        <>
        <div className={"grid-charts"}>
            <TransactionCharts/>
            <TransactionCharts/>
            <TransactionCharts/>
            <TransactionCharts/>
        </div>
       
        </>
    )
}

export default TransactionsGraphic